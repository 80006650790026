@import '../utils/vars.scss';

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html,
body {
	height: 100%;
	min-height: 100vh;
	max-width: 100vw;
	overflow-x: hidden;
	position: relative;
}

#root {
	min-height: 100%;
	position: relative;
}

body {
	font-family: $base-font-family;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: none;
	font-size: $main-font-size;
	line-height: $main-line-height;
	color: $text-color;
	margin: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

a {
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
}

ul {
	list-style: none;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

input[type='search'] {
	-webkit-appearance: textfield;
}

a,
button,
input,
textarea,
select {
	&:focus {
		outline: none;
	}
}

.ant-spin-spinning {
	display: block;
	margin: 200px auto;
}

//for preventing scroll on body
.no-scroll {
	height: 100vh;
	overflow-y: hidden;
}
