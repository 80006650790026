$fontsDir: '../../assets/fonts/';
@font-face {
	font-family: 'icomoon';
	src: url('#{$fontsDir}icomoon.woff?baxbj3') format('woff'),
		url('#{$fontsDir}icomoon.svg?baxbj3#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-linkedin::before {
	content: '\e900';
}
.icon-instagram::before {
	content: '\e901';
}
.icon-facebook::before {
	content: '\e902';
}
.icon-twitter::before {
	content: '\e903';
}
.icon-success::before {
	content: '\e904';
}
.icon-alert::before {
	content: '\e905';
}
