.container {
	width: 340px;
	margin: 0 auto;

	@media (max-width: 370px) {
		width: 80%;
	}

	@media (min-width: 900px) {
		width: 800px;
	}

	@media (min-width: 1200px) {
		width: 1050px;
	}

	@media (min-width: 1400px) {
		width: 1280px;
	}
}

.dashContainer {
	overflow-x: hidden;
	padding: 0 58px;
}

h1,
h2 {
	font-size: 34px;
	line-height: 44px;
	font-weight: 700;
}
