@import './base/resets';
@import './utils/vars.scss';
@import './utils/mixins.scss';
@import './base/common.scss';
@import './base/fonts';
@import './base/iconmoon.scss';
@import '~antd/dist/antd.css';
@import './lib/hamburger-react.scss';
@import './lib/antd/dropdown.scss';

:root {
	--light: #ffffff;
	--dark: #000000;
	--grey: #8c8c8c;
	--dark--text: #343434;
	--dark--bg: #23252a;
	--main-blue: #2186ef;
	--secondary-main: #b79d81;
	--dark-blue: #2b2f48;
	--secondary-blue: #7086a2;
	--border: #e7e6e4;
	--alert: #dc2424;
	--divider: #c4c4c4;
	--footer-bg: #282a29;
}
