@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
$fontsDir: '../../assets/fonts/';

@font-face {
	font-family: 'Open Sans';
	src: url('#{$fontsDir}OpenSans-Regular.ttf') format('ttf');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('#{$fontsDir}OpenSans-SemiBold.ttf') format('ttf');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('#{$fontsDir}OpenSans-Bold.ttf') format('ttf');
	font-weight: 700;
	font-style: normal;
}
