@import './media-queries-mixins';

@mixin text-style-headline-1 {
	font-family: Anton;
	font-size: 31px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	letter-spacing: 1.24px;

	@include tablet {
		font-size: 58px;
		letter-spacing: 2.32px;
	}

	@include desktop {
		font-size: 76px;
		letter-spacing: 3.04px;
	}
}

@mixin text-style-headline-2 {
	font-family: Anton;
	font-size: 30px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	letter-spacing: 1.2px;

	@include tablet {
		font-size: 54px;
		letter-spacing: 2.16px;
	}

	@include desktop {
		font-size: 61px;
		letter-spacing: 2.44px;
	}
}

@mixin text-style-headline-3 {
	font-family: Anton;
	font-size: 28px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	letter-spacing: 1.12px;

	@include tablet {
		font-size: 46px;
		letter-spacing: 1.84px;
	}

	@include desktop {
		font-size: 49px;
		letter-spacing: 1.96px;
	}
}

@mixin text-style-headline-4 {
	font-family: Anton;
	font-size: 26px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	letter-spacing: 1.04px;

	@include tablet {
		font-size: 39px;
		letter-spacing: 1.56px;
	}
}

@mixin text-style-headline-5 {
	font-family: Anton;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	letter-spacing: 0.96px;

	@include tablet {
		font-size: 31px;
		letter-spacing: 1.84px;
	}
}

@mixin text-style-headline-6 {
	font-family: Anton;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	letter-spacing: 0.88px;

	@include tablet {
		font-size: 25px;
		letter-spacing: 1px;
	}
}

@mixin text-style-large {
	font-family: Anton;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	letter-spacing: 0.8px;
}

@mixin text-style-base {
	font-family: Anton;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	letter-spacing: 0.64px;
}

@mixin text-style-small {
	font-family: Anton;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	letter-spacing: 0.52px;
}

@mixin text-style-xsmall {
	font-family: Anton;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	letter-spacing: 0.4px;
}
