@import '../../mixins/text-mixins';
@import '../../mixins/media-queries-mixins';

.header__dropdown {
	&.ant-dropdown-menu {
		span {
			@include text-style-large;
		}

		@include desktopStandard {
			display: flex;
			justify-content: center;
			width: 175px;
			height: 44px;
		}
	}
}
